import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/web-toit.io/web-toit.io/src/components/MdxLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const Section = makeShortcode("Section");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageTitle title="Subprocessors" subtitle="Current as of: April 10, 2021" mdxType="PageTitle" />
    <Section mdxType="Section">
      <p>{`Welcome to Toitware ApS’s Subprocessor repository page where we maintain a
current list of Subprocessors authorized to process customer data for our
services.`}</p>
      <h2>{`Entities`}</h2>
      <p><strong parentName="p">{`Amazon Web Services, Inc.`}</strong><br parentName="p"></br>{`
`}{`Entity Location: USA`}</p>
      <p><strong parentName="p">{`Slack, Inc.`}</strong><br parentName="p"></br>{`
`}{`Entity Location: USA`}</p>
      <p><strong parentName="p">{`Hubspot, Inc.`}</strong><br parentName="p"></br>{`
`}{`Entity Location: USA`}</p>
      <p><strong parentName="p">{`Google LLC.`}</strong><br parentName="p"></br>{`
`}{`Entity Location: USA`}</p>
      <p><strong parentName="p">{`The Rocket Science Group LLC. (Mailchimp)`}</strong><br parentName="p"></br>{`
`}{`Entity Location: USA`}</p>
      <p><strong parentName="p">{`Twilio Inc.`}</strong><br parentName="p"></br>{`
`}{`Entity Location: USA`}</p>
      <p><strong parentName="p">{`Segment.io, Inc.`}</strong><br parentName="p"></br>{`
`}{`Entity Location: USA`}</p>
      <p><strong parentName="p">{`Amplitude, Inc.`}</strong><br parentName="p"></br>{`
`}{`Entity Location: USA`}</p>
      <p><strong parentName="p">{`Humio Ltd.`}</strong><br parentName="p"></br>{`
`}{`Entity Location: United Kingdom`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      